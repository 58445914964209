.root {
  overflow: hidden;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px !important;
  width: calc(90%);
  max-width: 400px;
}

.buttonGroup a {
  display: block;
  width: 11.875rem;
  margin: 1rem auto 0;
}

.content {
  position: relative;
  z-index: 10;
  max-width: 33rem;
  margin: -2.5rem auto 0;
  padding: 0 1.5rem;
}

.imgWrap {
  flex: 1;
  aspect-ratio: 1 / 1;
  margin-bottom: 40px;
}

@media (min-width: 320px) {
  .imgWrap {
    position: relative;
  }
  .imgWrap::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    background: linear-gradient(
      to bottom,
      rgba(13, 13, 13, 0) 0%,
      #0d0d0d 100%
    );
  }

  .root {
    max-width: 400px;
    margin: 2rem auto;
    padding-bottom: 8rem;
    border: 12px solid #050505;
    border-radius: 40px;
    background-color: #0d0d0d;
  }
}
